<template>
  <div v-if="admin_roles !== false" class="view content-padding">
    <BlocksEditor
      v-if="singlePage" 
      :entity-type="'page'"
      :entity-label="'Page'"
      :template-mode="true"
    >
      <template v-slot:sidebar>
        <BaseCard padding="25px" margin="0 0 30px">
          <FormulateInput
            class="active-page"
            type="checkbox"
            name="pageActive"
            v-bind:disabled="!currentUserCan()"
            v-bind:key="'page-' + isPageActive"
            v-bind:checked="isPageActive"
            v-bind:label="labels.active_page"
            v-on:change="togglePageActivation($event.target.checked, id)"
          />
        </BaseCard>
      </template>
    </BlocksEditor>
  </div>
</template>

<script>
import BlocksEditor from "@/views/Dashboard/Common/BlocksEditor";
import BaseCard from "@/components/Cards/BaseCard.vue";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    BlocksEditor,
    BaseCard,
  },

  computed: {
    ...mapState("helper", {
      admin_roles: "admin_roles",
      basicDataLoaded: "basicDataLoaded",
      features: "features",
    }),
    ...mapState("blockEditor", {
      singlePage: "singleEntity",
      changesCount: "changesCount",
      slotsChangesCount: "slotsChangesCount",
    }),
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
    id() {
      if (this.singlePage && this.singlePage._id) {
        return this.singlePage._id;
      }
      return null;
    },
    isPageActive() {
      if (this.singlePage && typeof this.singlePage.active !== "undefined") {
        return this.singlePage.active;
      }
      return false;
    },
  },
  watch: {
    basicDataLoaded(newValue) {
      // Means basic data loaded, running all needed stuff
      if (newValue) this.processPage();
    },
  },
  methods: {
    ...mapActions("page", {
      setActiveState: "setActiveState",
    }),
    ...mapMutations("blockEditor", {
      setEntityPropValue: "setEntityPropValue",
      setChangesCount: "setChangesCount",
      setSlotChangesCount: "setSlotChangesCount",
    }),
    currentUserCan() {
      if (Array.isArray(this.admin_roles) && this.admin_roles.includes("web")) {
        return true;
      }
      return false;
    },
    togglePageActivation(active, pageId) {
      this.setActiveState({
        id: pageId,
        action: active ? "activate" : "deactivate",
      });
    },
    processPage() {
      // if (!this.basicDataLoaded) return;
      // if (!this.features.websystem || !this.hasPermissions(['super', 'web'])) {
      //     this.$router.push({name: 'dashboard'});
      //     return;
      // }
    },
  },
  // prevents leaving editor page with unsaved changes
  beforeRouteLeave(to, from, next) {
    if (this.changesCount > 0 || this.slotsChangesCount > 0) {
      const answer = window.confirm(
        this.labels.are_you_sure_you_want_to_leave_page
      );
      if (answer) {
        this.setChangesCount(0);
        this.setSlotChangesCount(0);
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  mounted() {
    this.processPage();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.active-page {
  &[data-classification="box"] {
    ::v-deep {
      label {
        margin-bottom: 0;
        font-size: 1rem;
        margin-top: 0;
        font-weight: bold;
        font-family: "Lato";
      }
      .formulate-input-label {
        margin-left: 10px;
        margin-bottom: 2px;
      }
    }
  }
}
.page-template {
  font-family: "Lato";
  font-weight: bold;
  font-size: 1rem;
  line-height: 200%;
  color: $dark;

  a {
    color: $focus_color;
    margin-left: 5px;

    &:hover {
      color: $lightBlue;
    }
  }
}
</style>
