<template>
  <div class="entites" :set="(_schema = schema())">
    <div class="entites__header">
      <HeaderLabel
        :label-buttons="labelButtons"
        :buttons-list="buttonsList"
        :separate-buttons="separateButtons"
        :title="entityLabel + 'title:'"
        :entity-label="title"
      >
        <template
          v-slot:header-actions
          v-if="!isMobile && !templateMode && !prefilledMode"
        >
          <div class="mb-3 mr-3 slot-selector">
            <div class="total-slots-changes" v-if="globalSlotsChangesCount">
              {{ globalSlotsChangesCount }}
            </div>
            <FormulateInput
              type="select"
              name="slot-select"
              :options="_slots"
              v-model="slotSelectValue"
              v-if="_slots && Object.keys(_slots).length > 0"
              :label="labels.available_slots"
              v-on:change="setActiveSlot($event.target.value)"
            />
          </div>
        </template>
      </HeaderLabel>
    </div>
    <div class="entites__block-list body-secondary" :class="commonWrapperClass">
      <BlockList
        v-if="!prefilledMode"
        v-bind:block-list="blocksList"
        v-bind:on-dragging="onDragging"
        v-bind:drag-end="dragEnd"
        v-bind:categories="categories"
        v-bind:key="blockListKey"
      />
    </div>
    <div
      class="entites__content content"
      :set="(_slots = slots())"
      :class="commonWrapperClass"
    >
      <div class="row content__body">
        <div class="col body-main" :class="commonWrapperClass">
          <div class="body-main__xActions" v-if="showXActions">
            <BaseCard
              padding="10px 25px 30px"
              margin="0 0 30px"
              :class="'xform-actions ' + xFormStatus"
              overflow="visible"
            >
              <XFormActions
                :xFormStatus="xFormStatus"
                :valueKey="'body'"
                :xActionsObj="xActionsObj"
                v-if="xActionsObj.select.length"
                :key="xActionsUpdate"
              />
              <span v-else>{{ labels.no_data }}</span>
            </BaseCard>
          </div>
          <div
            class="body-main__wrapper"
            v-bind:key="advancedBlockKey + currentSlot"
            v-if="
              (!templateMode && _slots && Object.keys(_slots).length > 0) ||
              templateMode
            "
            :set="(_structure = structure())"
          >
            <div
              class="drop-area"
              v-if="currentUserCan('write') && !prefilledMode"
            >
              <CircleButton
                class="mb-0"
                :bg-color="`${colorsHtml.navy}`"
                icon="plus"
              />
              <div
                v-bind:id="`drop-area__0`"
                v-on:dragover="allowDrop"
                v-on:dragleave="dragLeave"
                v-on:drop="drop"
                class="drop-area__mask"
              ></div>
            </div>
            <div
              v-for="(item, blockIndex) in _structure"
              v-bind:key="blockIndex"
            >
              <template>
                <!-- <template v-if="!item.dataId.endsWith( '_PREFILLED' )"> -->
                <div
                  v-click-outside="deactivateActiveBlock"
                  :blockid="item.dataId"
                  v-on:dragstart="onDragging"
                  v-on:dragend="dragEnd"
                  v-bind:draggable="true"
                  v-on:click="setActiveBlock($event)"
                  v-bind:transfer-id="`${item.component}__${blockIndex}`"
                  class="block-item"
                >
                  <AdvancedBlock
                    v-bind:cpt-settings="cptSettings"
                    v-bind:structure-item="item"
                    v-bind:block-object="blocksList[item.component]"
                    v-bind:block-values="data()[item.dataId]"
                    v-bind:block-slots="_slots"
                    v-bind:key="blockIndex + item.dataId + advancedBlockKey"
                    v-bind:structure="_structure"
                    :prefilled-mode="prefilledMode"
                    v-bind:block-index="parseInt(blockIndex)"
                    v-bind:disabled="!currentUserCan('write')"
                    v-bind:functions="advancedBlockFunctions"
                    v-bind:blocksList="blocksList"
                    v-bind:data="data()"
                    v-bind:activateBlockFn="setActiveBlock"
                    :schema="_schema[item.dataId]"
                    @set-default-settings="setDefaultSettings"
                  />
                </div>
                <div
                  class="drop-area"
                  v-if="currentUserCan('write') && !prefilledMode"
                >
                  <CircleButton
                    class="mb-0"
                    :bg-color="`${colorsHtml.navy}`"
                    icon="plus"
                  />
                  <div
                    v-bind:id="`drop-area__${blockIndex + 1}`"
                    v-on:dragover="allowDrop"
                    v-on:dragleave="dragLeave"
                    v-on:drop="drop"
                    class="drop-area__mask"
                  ></div>
                </div>
                <div v-else class="block-editor-spacer"></div>
              </template>
            </div>
          </div>
          <div v-else class="body-main__wrapper" style="text-align: center">
            <h3>{{ labels.no_slots_available }}</h3>
          </div>
        </div>
        <div class="col body-sidebar" :class="commonWrapperClass">
          <ActionPanel
            v-click-outside="deactivateActiveBlock"
            class="body-sidebar_card"
            :save_label="labels.save"
            changes="changes"
            :charges_count_show="false"
            saveBtnType="submit"
            :saveFN="editEntity"
            :saveBtnDisabled="saveBtnDisabled || totalChangesCount() === 0"
            :enabled="currentUserCan('write')"
            :actionsEnabled="
              currentUserCan('approve') ||
              currentUserCan('reject') ||
              currentUserCan('write')
            "
            :last_action="[]"
          />

          <slot name="sidebar"></slot>

          <BlockSettings
            header="Block XY"
            v-click-outside="deactivateActiveBlock"
            v-bind:dataId="activeBlock.dataId"
            v-bind:data-object="data()[activeBlock.dataId]"
            v-bind:active-block="blocksList[activeBlock.component]"
            v-bind:settings-key="settingsKey"
            v-bind:prefilled-mode="prefilledMode"
            v-bind:disabled="!currentUserCan('write')"
            :schema="_schema[activeBlock.dataId]"
            @set-default-settings="setDefaultSettings"
          />
        </div>
      </div>
    </div>
    <div
      class="scroll-top"
      ref="scroll-top"
      v-on:dragover="overScrollTop"
      v-on:dragleave="dragLeave"
    >
      <div v-on:dragover="allowScroll">
        <svg viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
          />
          <path
            fill-rule="evenodd"
            d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
          />
        </svg>
      </div>
    </div>
    <div
      class="scroll-bottom"
      ref="scroll-bottom"
      v-on:dragover="overScrollBottom"
      v-on:dragleave="dragLeave"
    >
      <div v-on:dragover="allowScroll">
        <svg viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />
          <path
            fill-rule="evenodd"
            d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
    </div>
    <router-view :name="`single_${entityType}_notes`"></router-view>
  </div>
</template>

<script>
const moment = require("moment");

import prepareForm from "@/services/prepare-formdata";

import getParents from "@/services/get-parents";

import getValuesChangesCount from "@/services/count-differences-between-objects";

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import colorsHtml from "/colors.config.json";
import {
  faBook,
  faCog,
  faTrash,
  faCheck,
  faSignInAlt,
  faExternalLinkAlt,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBook,
  faCog,
  faTrash,
  faCheck,
  faSignInAlt,
  faExternalLinkAlt,
  faPlus,
  faTimes
);

export default {
  name: "BlocksEditor",
  components: {
    BlockList: () => import("@/components/Blocks/BlockList"),
    AdvancedBlock: () => import("@/components/Blocks/AdvancedBlock"),
    ActionPanel: () => import("@/components/Panels/ActionPanel"),
    CircleButton: () => import("@/components/Buttons/CircleButton"),
    BlockSettings: () => import("@/components/Blocks/BlockSettings"),
    XFormActions: () => import("@/components/Forms/XFormActions"),
    HeaderLabel: () => import("@/components/Labels/HeaderLabel"),
    BaseCard: () => import("@/components/Cards/BaseCard.vue"),
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityLabel: {
      required: true,
    },
    templateMode: {
      type: Boolean,
      default: false,
    },
    cpt: {
      type: Object,
      default: () => {},
    },
    cptName: {
      type: String,
      default: "",
    },
    prefilledMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      loadedData: false,
      blockListKey: Date.now(),
      slotSelectValue: "",
      isMobile: false,
      allowScrollVar: false,
      saveBtnDisabled: false,
      showXActions: false,
      xFormStatus: "",
      originalSingleEntity: {},
      settingsKey: Date.now(),
      advancedBlockKey: Date.now(),
      xActionsUpdate: Date.now(),
      localChanges: 0,
      localSlotChanges: 0,

      commonWrapperClass: {},
    };
  },
  mounted() {
    this.fetchForAdvancedBlock();
    let self = this;

    this.getSingleEntityWrapper(
      {
        id: self.id,
      },
      () => {
        self.loadedData = true;
      }
    );

    this.assignLongTermVariables();

    this.setCurrentSlot("");

    this.listBlocks().then((res) => {
      if (res.success) {
        if (
          (this.blocksList && Object.keys(this.blocksList).length === 0) ||
          !this.blocksList
        ) {
          // add core blocks like menu / slot(for template mode only) and commit to state
          self.registerCoreBlocks(res.data.blocks, res.data.categories);
        }
      }
    });

    window.addEventListener("resize", () => {
      self.isMobile = screen.width < 1280;
    });

    window.dispatchEvent(new Event("resize"));

    this.$root.$on("update-xFormActions", (data) => {
      if (
        this.originalSingleEntity[data.key] &&
        this.originalSingleEntity[data.key][this.currentSlot]
      ) {
        this.setEntityPropValue({
          key: data.key,
          value: this.originalSingleEntity[data.key][this.currentSlot].value,
          slotId: this.currentSlot,
        });
      }
      this.xActionsUpdate = Date.now();
      this.advancedBlockKey = Date.now();
    });
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("blockEditor", {
      activeBlock: "activeBlock",
      blocksList: "blocksList",
      categories: "categories",
      singleEntity: "singleEntity",
      currentSlot: "currentSlot",
    }),
    xActionsObj() {
      return {
        select: [],
        selected: "",
        selectAction: this.selectActionFN,
        save: {
          text: this.labels.save,
          action: this.xFormSave,
        },
        note: {
          text: this.labels.note,
          action: this.xFormNotes,
        },
        approve: {
          text: this.labels.approve,
          action: this.xFormApprove,
          icon: "check",
        },
        reject: {
          text: this.labels.reject,
          action: this.xFormReject,
          icon: "trash",
        },
        reinstate: {
          text: this.labels.reinstate,
          action: this.xFormReinstate,
          icon: "sign-in-alt",
        },
        remove: {
          action: this.xFormReject,
          icon: "trash",
        },
        loopKey: Date.now(),
      };
    },
    headerTitle() {
      return "";
    },
    advancedBlockFunctions() {
      return {
        allowDrop: this.allowDrop,
        dragLeave: this.dragLeave,
        drop: this.drop,
        onDragging: this.onDragging,
        dragEnd: this.dragEnd,
      };
    },
    id() {
      return this.$route.params.id;
    },
    title() {
      if (
        this.singleEntity &&
        this.singleEntity.title &&
        this.singleEntity.title.value
      )
        return this.singleEntity.title.value;

      return `${this.entityLabel}`;
    },
    createTime() {
      if (this.singleEntity && this.singleEntity.created)
        return moment(this.singleEntity.created.time).format(
          "DD.MM.YYYY - hh:mm"
        );

      return "";
    },
    lastModifyTime() {
      if (this.singleEntity && this.singleEntity.updated)
        return moment(this.singleEntity.updated.time).format(
          "DD.MM.YYYY - hh:mm"
        );

      return "";
    },
    globalSlotsChangesCount() {
      return this.countAllSlotsChanges();
    },
    cptSettings() {
      if (this.singleEntity && this.singleEntity.cpt_settings)
        return this.singleEntity.cpt_settings;

      return {};
    },
    /* Returns 'active' page state */
    isPageActive() {
      if (
        !this.templateMode &&
        this.singleEntity &&
        typeof this.singleEntity.active !== "undefined"
      ) {
        return this.singleEntity.active;
      }
      return false;
    },
    separateButtons() {
      return [
        {
          label: this.labels.discard_changes,
          icon: "times",
          show: this.totalChangesCount(),
          action: this.discardChanges,
          disabled: !this.currentUserCan("write"),
          variant: "outline-danger",
          titleAttribute: "discard changes",
          bgColor: "#FEDFE5",
          color: "#F56868",
          iconPosition: "right",
          filter: "brightness(100%)",
          opacity: "0.8",
        },
        {
          label: `exit ${this.xFormStatus} mode`,
          icon: "",
          show: this.showXActions,
          action: this.goToCurrent,
          disabled: !this.currentUserCan("write"),
          variant: "outline-warning",
          titleAttribute: `exit ${this.xFormStatus} mode`,
        },
      ];
    },
    labelButtons() {
      return [
        {
          label: this.cptName?.length ? this.cptName : this.entityType,
          icon: "",
          show: true,
          action: () => {},
          disabled: false,
          class: "current",
          titleAttribute: this.entityType,
        },
      ];
    },
    buttonsList() {
      return [
        {
          label: "",
          icon: "external-link-alt",
          link: this.permalink(),
          disabled: false,
          show:
            !this.templateMode && this.isPageActive && !this.cptName?.length,
          titleAttribute: "preview",
        },
        {
          label: "",
          icon: "cog",
          show: !this.settingsActive && !this.cptName?.length,
          action: () => {
            this.$router.push({
              name: "single_page_settings",
              params: {
                id: this.$route.params.id,
              },
            });
          },
          disabled: false,
          titleAttribute: "general settings",
        },
      ];
    },
  },
  methods: {
    // map functions for editor
    ...mapActions('helper', ['fetchForAdvancedBlock']),
    ...mapMutations("blockEditor", {
      updateStructure: "updateStructure",
      updateActiveBlock: "updateActiveBlock",
      setEntityPropValue: "setEntityPropValue",
      setSingleEntity: "setSingleEntity",
      setCurrentSlot: "setCurrentSlot",
      setChangesCount: "setChangesCount",
      setSlotChangesCount: "setSlotChangesCount",
      setBlocksList: "setBlocksList",
      deleteRevision: "deleteRevision",
    }),
    ...mapActions("blockEditor", {
      listBlocks: "listBlocks",
    }),
    getEntityForEditor(payload) {
      let upperCaseFirst =
        this.entityType[0].toUpperCase() +
        this.entityType.slice(1, this.entityType.length);
      return this.$store.dispatch(
        `${this.entityType}/get${upperCaseFirst}ForEditor`,
        payload
      );
    },
    updateEntity(payload) {
      if (this.singleEntity && this.singleEntity.cpt_slug) {
        return this.$store.dispatch(`cpt/updateCPTPage`, payload);
      }
      let upperCaseFirst =
        this.entityType[0].toUpperCase() +
        this.entityType.slice(1, this.entityType.length);
      return this.$store.dispatch(
        `${this.entityType}/update${upperCaseFirst}`,
        payload
      );
    },
    ...mapActions("values", {
      getValue: "getValue",
      approveValue: "approveValue",
      rejectValue: "rejectValue",
      saveValue: "saveValue",
      reinstateValue: "reinstateValue",
    }),
    ...mapActions("menu", {
      getAllMenuList: "getAllMenuList",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    currentUserCan() {
      return true;
    },
    // assignes local variables to use during render instead of doing calcuations in computed property
    assignLongTermVariables() {
      this.commonWrapperClass = this.getWrapperClass();
    },
    schema() {
      let output = {};
      if (
        this.singleEntity &&
        this.singleEntity.additional &&
        this.singleEntity.additional.schema
      ) {
        output = this.singleEntity.additional.schema;
      }
      return output;
    },
    // returns the block structure of current page / slot
    structure() {
      let output = [];

      if (this.templateMode) {
        if (
          this.singleEntity &&
          this.singleEntity.body &&
          this.singleEntity.body.value &&
          this.singleEntity.body.value.structure
        ) {
          if (!Array.isArray(this.singleEntity.body.value.structure)) {
            output = Object.values(this.singleEntity.body.value.structure);
          } else {
            output = this.singleEntity.body.value.structure;
          }
        }
      } else {
        if (
          this.currentSlot !== "" &&
          this.singleEntity &&
          this.singleEntity.body &&
          this.singleEntity.body[this.currentSlot] &&
          this.singleEntity.body[this.currentSlot].value &&
          this.singleEntity.body[this.currentSlot].value.structure
        ) {
          if (
            !Array.isArray(
              this.singleEntity.body[this.currentSlot].value.structure
            )
          ) {
            output = Object.values(
              this.singleEntity.body[this.currentSlot].value.structure
            );
          } else {
            output = this.singleEntity.body[this.currentSlot].value.structure;
          }
        }
      }

      let self = this;

      let assignIds = function (arr, baseId) {
        if (!baseId) baseId = "";

        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].dataId || arr[i].dataId.length === 0) {
            arr[i].dataId = baseId + self.makeId();
          }
          if (arr[i].children) {
            arr[i].children = assignIds(arr[i].children, arr[i].dataId + "_");
          }
        }

        return arr;
      };

      output = assignIds(output);

      return output;
    },
    // returns the data for blocks in current structure / slot
    data() {
      if (this.templateMode) {
        if (
          this.singleEntity &&
          this.singleEntity.body &&
          this.singleEntity.body.value &&
          this.singleEntity.body.value.data
        ) {
          return this.singleEntity.body.value.data;
        }
      } else {
        if (
          this.currentSlot !== "" &&
          this.singleEntity &&
          this.singleEntity.body &&
          this.singleEntity.body[this.currentSlot] &&
          this.singleEntity.body[this.currentSlot].value &&
          this.singleEntity.body[this.currentSlot].value.data
        ) {
          return this.singleEntity.body[this.currentSlot].value.data;
        }
      }
      return {};
    },
    // returns slots for current page/template
    slots() {
      if (this.templateMode) {
        if (
          this.singleEntity &&
          this.singleEntity.body &&
          this.singleEntity.body.value &&
          this.singleEntity.body.value.slots
        ) {
          return this.singleEntity.body.value.slots;
        }
      } else {
        if (this.singleEntity && this.singleEntity.slots) {
          if (!this.slotSelectValue || this.currentSlot === "") {
            if (Object.keys(this.singleEntity.slots).length) {
              this.slotSelectValue = Object.keys(this.singleEntity.slots)[0];
              this.setActiveSlot(this.slotSelectValue);
            }
          }
          return this.singleEntity.slots;
        }
      }
      return {};
    },
    permalink() {
      if (
        this.singleEntity &&
        this.singleEntity.permalink &&
        this.singleEntity.permalink.value
      ) {
        let cptSlug = "";

        if (
          this.singleEntity.cpt_settings &&
          this.singleEntity.cpt_settings.settings &&
          this.singleEntity.cpt_settings.settings.base_path
        ) {
          cptSlug = this.singleEntity.cpt_settings.settings.base_path;
          if (!cptSlug.startsWith("/")) {
            cptSlug = "/" + cptSlug;
          }
        }

        if (!this.singleEntity.permalink.value.startsWith("/")) {
          return cptSlug + "/" + this.singleEntity.permalink.value;
        }
        return cptSlug + this.singleEntity.permalink.value;
      }
      return "#";
    },
    // set default settings for originalSingleEntity
    setDefaultSettings(payload) {
      if (this.currentSlot || !this.templateMode) {
        let id = payload.dataId || this.activeBlock?.dataId;

        if (
          this.originalSingleEntity?.body &&
          this.originalSingleEntity?.body[this.currentSlot] &&
          !this.originalSingleEntity?.body[this.currentSlot]?.value?.data
        ) {
          this.originalSingleEntity.body[this.currentSlot].value.data = {};
        }

        if (
          this.originalSingleEntity?.body &&
          this.originalSingleEntity?.body[this.currentSlot] &&
          !this.originalSingleEntity?.body[this.currentSlot]?.value?.data[id]
        ) {
          this.originalSingleEntity.body[this.currentSlot].value.data[id] = {};
        }

        if (this.originalSingleEntity.body) {
          if (!this.originalSingleEntity.body[this.currentSlot]) {
            this.originalSingleEntity.body[this.currentSlot] = {
              value: { data: { [id]: { [payload.key]: payload.value } } },
            };
          }

          this.originalSingleEntity.body[this.currentSlot].value.data[id][
            payload.key
          ] = payload.value;
        }
      }
    },
    // gets single entity for editor and re-sets some keys
    getSingleEntityWrapper(payload, callback) {
      let self = this;

      // append 'editor' mode to get needed fields for settings page
      payload.mode = "editor";

      this.getEntityForEditor(payload)
        .then((res) => {
          // refresh only fields provided
          if (
            payload.fieldsToRefresh &&
            Array.isArray(payload.fieldsToRefresh)
          ) {
            // force refresh updated fields
            payload.fieldsToRefresh.forEach(function (field) {
              if (typeof self.singleEntity[field] === "object") {
                self.originalSingleEntity[field] = JSON.parse(
                  JSON.stringify(self.singleEntity[field])
                );
              } else {
                self.originalSingleEntity[field] = self.singleEntity[field];
              }
            });
          } else {
            self.originalSingleEntity = JSON.parse(
              JSON.stringify(self.singleEntity)
            );
          }

          self.xActionsObj.loopKey = Date.now();
          self.advancedBlockKey = Date.now();
          self.settingsKey = Date.now();

          if (typeof callback === "function") callback(res);
        })
        .catch((err) => console.log(err));
    },
    // resets any changes made to object by replacing it with the one from the server
    discardChanges() {
      let self = this;
      self.getSingleEntityWrapper(
        {
          id: self.id,
        },
        (res) => {
          self.resetSlotValue(res);
        }
      );
    },
    // returns the count of the difference between original object and upated version of original object
    totalChangesCount() {
      if (this.showXActions) return 0;
      if (!this.loadedData) return 0;

      if (this.currentSlot === "" || this.templateMode) {
        let count = getValuesChangesCount(
          this.originalSingleEntity,
          this.singleEntity
        );
        if (this.localChanges !== count) {
          this.localChanges = count;
          this.setChangesCount(count);
        }
        return count;
      } else {
        if (!this.originalSingleEntity.body) {
          return 0;
        }
        let count = getValuesChangesCount(
          this.originalSingleEntity.body[this.currentSlot],
          this.singleEntity.body[this.currentSlot]
        );
        if (this.localChanges !== count) {
          this.localChanges = count;
          this.setChangesCount(count);
        }
        return count;
      }
    },
    // counts the fields in 'changes' property(array)
    getDisplayChangesCount(valueKey) {
      if (typeof valueKey === "undefined") valueKey = "change";

      if (this.singleEntity && this.singleEntity[valueKey]) {
        let length = 0;

        for (let key in this.singleEntity[valueKey]) {
          if (
            key === "body" ||
            (this.currentSlot !== "" && key === `body.${this.currentSlot}`)
          ) {
            length += this.singleEntity[valueKey][key].length;
          }
        }

        return length;
      }

      return 0;
    },
    // counts the fields in 'revision' property(array)
    getRevisionsCount() {
      return this.getDisplayChangesCount("revision");
    },
    // sets data for xFormActions (dropdown items which will allow us to query values from database using selectActionFN)
    setXFormData(action) {
      if (!this.singleEntity[action]) return;

      let localObj = JSON.parse(JSON.stringify(this.singleEntity[action]));

      if (
        this.currentSlot !== "" &&
        this.singleEntity[action][`body.${this.currentSlot}`]
      ) {
        localObj.body = this.singleEntity[action][`body.${this.currentSlot}`];
      }

      if (!Array.isArray(localObj.body)) {
        this.xActionsObj.select = [];
        return;
      }

      this.xActionsObj.select = localObj.body.map((item) => {
        let date_formated = moment(item.created).format("DD.MM.YYYY - hh:mm");
        return {
          key: item.key,
          value: item._id,
          label: date_formated,
        };
      });
    },
    // takes data from database for specified value ID and then refreshes global object's field (provided by variable 'key')
    selectActionFN(id, key) {
      let label = this.labels;
      let self = this;
      self
        .getValue(id)
        .then((res) => {
          if (res.success) {
            let slotId = "";

            if (self.currentSlot !== "" && res.data.key.startsWith("body.")) {
              slotId = res.data.key.split(".")[1];
            }

            self.addNotification({
              variant: "success",
              msg: label[res.msg],
            });

            self.setEntityPropValue({
              key: key,
              value: res.data.value,
              slotId: slotId,
            });

            self.xActionsObj.loopKey = Date.now();
            self.advancedBlockKey = Date.now();
            self.settingsKey = Date.now();
          } else if (res.error) {
            res.error.forEach((err) => {
              self.addNotification({
                variant: "danger",
                msg: label[err],
              });
            });
          }
        })
        .catch((err) => console.log(err));
    },
    // sends updated object to server in order to save it (wrapper for state action)
    editEntity() {
      if (this.totalChangesCount() === 0) {
        this.addNotification({
          variant: "warning",
          msg: this.labels.nothing_to_update_here,
        });
        return;
      }

      let self = this;

      let payload = {};
      payload.body = {};

      if (this.templateMode) {
        payload.body.structure = this.structure();
        payload.body.data = this.data();
        payload.body.slots = this.slots();
      } else {
        if (
          this.singleEntity.body[this.currentSlot] &&
          this.singleEntity.body[this.currentSlot].value
        ) {
          payload.body[this.currentSlot] =
            this.singleEntity.body[this.currentSlot].value;
        } else {
          payload.body[this.currentSlot] = {};
        }
      }

      this.updateEntity(
        prepareForm({
          payload: payload,
          id: this.id,
        })
      ).then((res) => {
        if (res.success) {
          self.addNotification({
            variant: "success",
            msg: this.labels.object_has_been_updated,
          });
          // query updated entity from database in order to refresh the fields
          let fieldsToRefresh = ["revision", "change", "body"];
          if (self.templateMode) {
            fieldsToRefresh.push("body");
          }
          self.getSingleEntityWrapper(
            {
              id: self.id,
              fieldsToRefresh: fieldsToRefresh,
            },
            (res) => {
              self.resetSlotValue(res);
            }
          );
        }
        // else if (res.error) {
        //     res.error.forEach(err => {
        //         if (1 !== 1) { //if (label && label[err] ) {
        //             self.addNotification( { variant: 'danger', } );
        //         } else {
        //             self.addNotification({
        //                 variant: 'danger',
        //                 msg: err,
        //             });
        //         }
        //     })
        // }
      });
    },
    // sets xFormStatus to 'pending changes' mode which allows us to look through the pending changes for current entity and approve/reject/save/note them
    pendingChanges(e) {
      if (this.getDisplayChangesCount()) {
        if (e) {
          window.scrollTo(0, 0);
        }
        this.showXActions = true;
        this.saveBtnDisabled = true;
        this.xFormStatus = "change";

        if (this.singleEntity.change) {
          this.setXFormData("change");
        }
      }
    },
    // sets xFormStatus to 'revision' mode which allows us to look through the revisions (old versions) for current entity and approve/reject/save/note them
    getRevisions(e) {
      if (this.getRevisionsCount()) {
        if (e) {
          window.scrollTo(0, 0);
        }
        this.showXActions = true;
        this.saveBtnDisabled = true;
        this.xFormStatus = "revision";

        if (this.singleEntity.revision) {
          this.setXFormData("revision");
        }
      }
    },
    // onDragging event handler, used while dragging the block
    onDragging(ev) {
      if (this.prefilledMode) return;

      let transferId = ev.target.getAttribute("transfer-id");

      if (transferId) {
        ev.dataTransfer.setData("text", transferId);
      }
      if (ev.target.getAttribute("blockid")) {
        ev.dataTransfer.setData("blockId", ev.target.getAttribute("blockid"));
      }
      this.$refs["scroll-top"].classList.add("active");
      this.$refs["scroll-bottom"].classList.add("active");
    },
    // allows scroll while in drag & drop block mode
    allowScroll() {
      this.allowScrollVar = true;
    },
    overScrollTop() {
      this.$refs["scroll-top"].style.backgroundColor = "#89E29E";
      let t;
      let top = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      if (top > 0 && this.allowScrollVar) {
        window.scrollBy(0, -10);
        t = setTimeout(this.overScrollTop, 100);
      } else {
        clearTimeout(t);
      }
    },
    overScrollBottom() {
      this.$refs["scroll-bottom"].style.backgroundColor = "#89E29E";
      let t;
      let scroll_height = document.documentElement.scrollHeight;
      let client_height = document.documentElement.clientHeight;
      let scroll_top = document.documentElement.scrollTop;
      if (scroll_top + client_height < scroll_height && this.allowScrollVar) {
        window.scrollBy(0, 10);
        t = setTimeout(this.overScrollBottom, 100);
      } else {
        clearTimeout(t);
      }
    },
    dragEnd() {
      if (this.prefilledMode) return;
      this.$refs["scroll-top"].classList.remove("active");
      this.$refs["scroll-bottom"].classList.remove("active");
    },
    allowDrop(ev) {
      if (this.prefilledMode) return;
      ev.currentTarget.style.background = "lightblue";
      ev.preventDefault();
    },
    dragLeave(ev) {
      if (this.prefilledMode) return;
      ev.currentTarget.style.background = "transparent";
      this.$refs["scroll-top"].style.backgroundColor = "#b7e2c1";
      this.$refs["scroll-bottom"].style.backgroundColor = "#b7e2c1";
      this.allowScrollVar = false;
    },
    makeId() {
      let str = "id_";
      return (
        str +
        "xxxxxxxx_xxxx_4xxx_yxxx_xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        })
      );
    },
    drop(ev) {
      if (this.prefilledMode) return;
      let parent = null;
      ev.preventDefault();
      ev.currentTarget.style.background = "transparent";
      let data = ev.dataTransfer.getData("text");
      console.log('First data:', data);
      let blockId = ev.dataTransfer.getData("blockId");
      data = data.split("__");
      let dropArea = ev.target.id.split("__");

      // get parent
      let possibleParent = ev.target.id.split("-drop");
      if (possibleParent[0] && possibleParent[0] !== ev.target.id) {
        parent = possibleParent[0];
      }

      if (!blockId) {
        blockId = undefined;
      }

      console.log('Data:', data);
      console.log('Drop area:', dropArea);
      console.log('Parent:', parent);
      console.log('Block id:', blockId);

      this.manipulateBlockFlow(data, dropArea, blockId, parent);
    },
    // adds/updates the structure with either new block or the old one but at the different position
    manipulateBlockFlow(data, dropArea, id, parent) {
      // make new block if id is not provided
      if (typeof id === "undefined") id = this.makeId();

      if (parent) {
        id = parent + "_" + id;
      }

      let blockObject = this.blocksList[data[0]];

      if (parseInt(data[1]) + 1 !== parseInt(dropArea[1])) {
        this.updateStructure({
          data,
          dropArea,
          id,
          block: blockObject,
          parent,
        });

        this.advancedBlockKey = Date.now();
      }
    },
    getBlockById(obj, id) {
      let self = this;
      if (obj.dataId === id) {
        return obj;
      }
      if (obj.children) {
        let children = Object.values(obj.children);
        for (let i = 0; i < children.length; i++) {
          let tryToGet = self.getBlockById(children[i], id);
          if (tryToGet !== false) {
            return tryToGet;
          }
        }
      }
      return false;
    },
    // makes block active(visible settings/quick actions). Only 1 block can be active at the time.
    setActiveBlock(e, block) {
      if (
        (block &&
          this.activeBlock &&
          block.dataId &&
          this.activeBlock.dataId &&
          this.activeBlock.dataId === block.dataId) ||
        (block &&
          this.activeBlock &&
          JSON.stringify(block) === JSON.stringify(this.activeBlock))
      )
        return;

      if (
        e &&
        e.target &&
        e.target.parentElement &&
        (e.target.parentElement.classList.contains(
          "advanced-block__quick-controls"
        ) ||
          e.target.parentElement.classList.contains("svg-inline--fa"))
      )
        return;

      if (!block && e && e.target) {
        let parents = getParents(e.target);
        let struct = this.structure();

        for (let i = 0; i < parents.length; i++) {
          let nearestBlockId = parents[i].getAttribute("id");
          if (nearestBlockId && nearestBlockId.length !== 0) {
            // find selected block by id
            let nearestBlock = {};
            for (let i = 0; i < struct.length; i++) {
              nearestBlock = JSON.parse(
                JSON.stringify(this.getBlockById(struct[i], nearestBlockId))
              );

              if (nearestBlock && nearestBlock.dataId) {
                if (
                  this.activeBlock &&
                  this.activeBlock.dataId === nearestBlock.dataId
                )
                  return;

                this.updateActiveBlock(nearestBlock);
                this.settingsKey = Date.now();
                return;
              }
            }
          }
        }
      } else {
        this.updateActiveBlock(block);
        this.settingsKey = Date.now();
      }
    },
    // makes slot active(only valid for page mode). Only 1 slot can be active at the time.
    setActiveSlot(slot) {
      if (this.templateMode) return;

      if (slot === this.currentSlot) return;

      this.setCurrentSlot(slot);

      if (this.showXActions) {
        let value;

        if (
          this.originalSingleEntity.body[this.currentSlot] &&
          this.originalSingleEntity.body[this.currentSlot].value
        ) {
          value = this.originalSingleEntity.body[this.currentSlot].value;
        } else {
          value = "__delete";
        }

        this.setEntityPropValue({
          key: "body",
          value: value,
          slotId: this.currentSlot,
        });

        this.setXFormData(this.xFormStatus);
      }

      this.settingsKey = Date.now();
      this.advancedBlockKey = Date.now();
      this.xActionsObj.loopKey = Date.now();
    },
    // deactivates active block(if there is any), used on click outside of block / block settings
    deactivateActiveBlock() {
      this.updateActiveBlock({});
      this.settingsKey = Date.now();
    },
    // removes all changes made and goes back right to "live" version of entity
    goToCurrent() {
      let self = this;

      let fieldsToRefresh = ["change", "revision"];
      if (self.templateMode) {
        fieldsToRefresh.push("body");
      }

      self.getSingleEntityWrapper(
        {
          id: self.id,
          fieldsToRefresh: fieldsToRefresh,
        },
        (res) => {
          if (res.success) {
            self.resetSlotValue(res, (res, value) => {
              if (value !== "__delete") {
                if (!self.originalSingleEntity.body[self.currentSlot]) {
                  self.originalSingleEntity.body[self.currentSlot] = {
                    value: JSON.parse(JSON.stringify(value)),
                  };
                } else {
                  self.originalSingleEntity.body[self.currentSlot].value =
                    JSON.parse(JSON.stringify(value));
                }
              }
            });

            self.showXActions = false;
            self.xFormStatus = "";
            self.saveBtnDisabled = false;
            self.xActionsObj.select = [];
            self.settingsKey = Date.now();
          }
        }
      );
    },
    // rejects pending change/revision selected (deletes it)
    xFormReject(id, key, update) {
      let label = this.labels;
      if (typeof update === "undefined") update = true;

      if (id) {
        let self = this;

        self
          .rejectValue(id)
          .then((res) => {
            if (res.success) {
              self.addNotification({
                variant: "success",
                msg: label[res.msg],
              });

              if (
                !update &&
                key &&
                self.singleEntity[self.xFormStatus] &&
                self.singleEntity[self.xFormStatus][key]
              ) {
                self.deleteRevision({ status: self.xFormStatus, key, id });
                self.setXFormData(self.xFormStatus);
              } else {
                let changes = self.singleEntity[self.xFormStatus];

                if (typeof key === "undefined") {
                  key = "body";
                }

                if (
                  (changes && changes[key] && changes[key].length == 1) ||
                  !changes ||
                  !changes[key]
                ) {
                  self.goToCurrent();
                } else {
                  let fieldsToRefresh = ["change", "revision"];
                  if (self.templateMode) {
                    fieldsToRefresh.push("body");
                  }
                  self.getSingleEntityWrapper(
                    {
                      id: self.id,
                      fieldsToRefresh: fieldsToRefresh,
                    },
                    (res) => {
                      if (res.success) {
                        self.resetSlotValue(res, (res, value) => {
                          // if value is updated then refresh it in originalSingleEntity object
                          if (value !== "__delete") {
                            if (
                              !self.originalSingleEntity.body[self.currentSlot]
                            ) {
                              self.originalSingleEntity.body[self.currentSlot] =
                                {
                                  value: JSON.parse(JSON.stringify(value)),
                                };
                            } else {
                              self.originalSingleEntity.body[
                                self.currentSlot
                              ].value = JSON.parse(JSON.stringify(value));
                            }
                          }
                        });

                        self.setXFormData(self.xFormStatus);
                      } else {
                        console.error("invalid_response");
                      }
                    }
                  );
                }
              }
            } else if (res.error) {
              res.error.forEach((err) => {
                self.addNotification({
                  variant: "danger",
                  msg: label[err],
                });
              });
            }
          })
          .catch((err) => console.log(err));
      }
    },
    // approves pending change selected (makes it live)
    xFormApprove(id) {
      let label = this.labels;
      if (id) {
        let self = this;

        self
          .approveValue(id)
          .then((res) => {
            if (res.success) {
              self.addNotification({
                variant: "success",
                msg: label[res.msg],
              });
              self.goToCurrent();
            } else if (res.error) {
              res.error.forEach((err) => {
                self.addNotification({
                  variant: "danger",
                  msg: label[err],
                });
              });
            }
          })
          .catch((err) => console.log(err));
      }
    },
    // approves revision selected (makes it live)
    xFormReinstate(id) {
      let label = this.labels;
      if (id) {
        let self = this;
        self
          .reinstateValue({
            id,
          })
          .then((res) => {
            if (res.success) {
              self.addNotification({
                variant: "success",
                msg: label[res.msg],
              });
              self.goToCurrent();
            } else if (res.error) {
              res.error.forEach((err) => {
                self.addNotification({
                  variant: "danger",
                  msg: label[err],
                });
              });
            }
          })
          .catch((err) => console.log(err));
      }
    },
    // saves revision/pending change selected (it is still stored as a pending change or revision but with updated data)
    xFormSave(id, key) {
      let label = this.labels;
      if (id) {
        let self = this;
        let payload = {
          id: id,
          value: self.singleEntity[key].value,
        };

        if (!self.templateMode && self.currentSlot !== "") {
          payload.value = self.singleEntity[key][self.currentSlot].value;
        }

        self
          .saveValue(prepareForm(payload))
          .then((res) => {
            if (res.success) {
              self.addNotification({
                variant: "success",
                msg: label[res.msg],
              });
            } else if (res.error) {
              res.error.forEach((err) => {
                self.addNotification({
                  variant: "danger",
                  msg: label[err],
                });
              });
            }
          })
          .catch((err) => console.log(err));
      }
    },
    // opens the popup for adding notes
    xFormNotes(id) {
      if (id) {
        let route = "";
        if (this.$route.path.endsWith("/"))
          route = `${this.$route.path}${id}/notes`;
        else route = `${this.$route.path}/${id}/notes`;

        this.$router.push(route);
      }
    },
    // resets slot content (body) to the value that either comes from server or stored in originalSingleEntity in case there is no value stored on server.
    // if both server and local value are undefined then we just delete all slot data (locally)
    resetSlotValue(res, callback) {
      if (this.templateMode) return;

      let self = this;
      let value;

      if (
        res.data.body &&
        res.data.body[self.currentSlot] &&
        res.data.body[self.currentSlot].value
      ) {
        value = res.data.body[self.currentSlot].value;
      } else if (
        self.originalSingleEntity.body &&
        self.originalSingleEntity.body[self.currentSlot] &&
        self.originalSingleEntity.body[self.currentSlot].value
      ) {
        value = self.originalSingleEntity.body[self.currentSlot].value;
      } else {
        value = "__delete";
      }
      self.setEntityPropValue({
        key: "body",
        value: value,
        slotId: self.currentSlot,
      });

      if (typeof callback === "function") callback(res, value);
    },
    // this function counts all changes across all slots
    countAllSlotsChanges() {
      let count = 0;
      let self = this;

      if (this.showXActions) return count;
      if (!this.loadedData) return count;

      if (this.currentSlot === "") {
        return count;
      }

      if (!this.originalSingleEntity.body) {
        return count;
      }

      Object.keys(self.slots()).forEach(function (slotId) {
        count += getValuesChangesCount(
          self.originalSingleEntity.body[slotId],
          self.singleEntity.body[slotId]
        );
      });

      if (this.localSlotChanges !== count) {
        this.localSlotChanges = count;
        this.setSlotChangesCount(count);
      }
      return count;
    },
    // registers some core blocks that can not be registered with config file
    registerCoreBlocks(blocks, categories) {
      // appends slot block when in template mode
      if (this.templateMode) {
        blocks["Slot"] = {
          category: "text_blocks",
          name: "Slot",
          is_slot: true,
          fields: {
            title: {
              datatype: "text",
              default: "",
              label: "Slot Title",
            },
          },
        };
        // update blocks list
        this.setBlocksList({
          blocks: blocks,
          categories: categories,
        });

        this.blockListKey = Date.now();
      }

      this.getAllMenuList().then((res) => {
        if (res.success && res.data && Array.isArray(res.data)) {
          let menuFields = {};
          res.data.forEach(function (menu) {
            menuFields[menu._id] = menu.title;
          });

          let menus = ["HeaderMenu", "FooterMenu"];

          menus.forEach((menuName) => {
            if (blocks[menuName].fields.menu) {
              blocks[menuName].fields.menu.options = JSON.parse(
                JSON.stringify(menuFields)
              );
            }
            if (
              blocks[menuName].fields.list &&
              blocks[menuName].fields.list.object.menu
            ) {
              blocks[menuName].fields.list.object.menu.options = JSON.parse(
                JSON.stringify(menuFields)
              );
            }
          });

          //finally set state variable with blocks list
          this.setBlocksList({
            blocks: blocks,
            categories: categories,
          });

          this.blockListKey = Date.now();
        }
      });
    },
    /* Returns common permanent(for session) wrapper class */
    getWrapperClass() {
      let base = {};
      if (this.prefilledMode) {
        base.prefilled = true;
      }

      return base;
    },
  },
  beforeDestroy() {
    this.setSingleEntity({
      body: {
        value: {
          data: {},
          structure: [],
          slots: {},
        },
      },
    });
    this.setBlocksList({ blocks: {}, categories: {} });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

::v-deep {
  * {
    font-family: "Montserrat", sans-serif !important;
  }
  .block-item__square,
  .block-item__title {
    text-align: center;
    font-weight: bold !important;
    color: $light_text !important;
  }
  .block-categories {
    .custom-button .text {
      display: none;
      width: 0;
    }
  }
}



.body-sidebar {
  ::v-deep {
    .custom-button {
      display: inline-block !important;
    }
  }
}

.body-secondary,
.body-main,
.body-sidebar,
.entites__content {
  --actionPanelWidth: 362px;
  --blockListWidth: 340px;
}

@media screen and (max-width: 1600px) {
  .body-secondary,
  .body-main,
  .body-sidebar,
  .entites__content {
    --actionPanelWidth: 362px;
    --blockListWidth: 300px;
  }
}

@media screen and (max-width: 1400px) {
  .body-secondary,
  .body-main,
  .body-sidebar,
  .entites__content {
    --actionPanelWidth: 362px;
    --blockListWidth: 260px;
  }
}

.body-secondary.prefilled,
.body-main.prefilled,
.body-sidebar.prefilled,
.entites__content.prefilled {
  --blockListWidth: 0px;
}

.block-editor-spacer {
  width: 100%;
  height: 25px;
}

/*.item:not(:first-child):not(:last-child) .drop-area {

opacity: 0;
    }
    .item:hover .drop-area, .item.draggingover{

opacity: 1 !important;
    }*/

::v-deep {
  .formulate-files {
    li {
      width: 100%;
    }
  }

  .formulate-file-name {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: block !important;
  }
}

.entites {
  display: flex;
  flex-wrap: wrap;

  .xform-actions {
    margin-top: 0 !important;

    &::before {
      display: none;
    }
  }

  &__header {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  // @media screen and (max-width: 1200px) {
  //     flex-wrap: wrap;
  // }

  &__block-list {
    width: var(--blockListWidth);

    .block-list-wrapper {
      max-height: calc(100vh - 130px);
      overflow-y: scroll;

      @media screen and (max-width: 1200px) {
        max-height: 400px;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  &__content.prefilled {
    padding-left: 0px;
  }

  &__content {
    padding-left: 40px;

    width: calc(100% - var(--blockListWidth));
    max-width: calc(100% - var(--blockListWidth));
    flex: 0 1 calc(100% - var(--blockListWidth));

    @media screen and (max-width: 1200px) {
      width: 100%;
      max-width: 100%;
      flex: 0 1 100%;

      padding-left: 0px;
    }

    .content {
      &__body {
        .body-main {
          width: calc(100% - var(--actionPanelWidth));
          max-width: calc(100% - var(--actionPanelWidth));
          flex: 0 1 calc(100% - var(--actionPanelWidth));

          > .list-group {
            &.empty {
              height: 100%;
            }
          }

          @media screen and (max-width: 1200px) {
            flex: 0 1 100%;
            max-width: 100%;
          }

          &__wrapper {
            .drop-area {
              transition: 0.3s;
              position: relative;
              padding-bottom: 30px;
              padding-top: 30px;
              margin-top: 30px;
              margin-bottom: 30px;
              background: $background;
              border: 1px dashed $light_text;
              border-radius: $border_radius;
              display: flex;
              justify-content: center;
              align-items: center;

              &__line {
                height: 4px;
                background-color: $light_gray;
              }

              .add-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              &__mask {
                position: absolute;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
              }
            }
          }
          &__xActions {
            position: sticky;
            top: 70px;
            z-index: 9;
          }
        }

        .body-sidebar {
          flex: 0 1 var(--actionPanelWidth);
          max-width: var(--actionPanelWidth);

          @media screen and (max-width: 1200px) {
            flex: 0 1 100%;
            max-width: 100%;
          }

          @media (min-width: 1200px) {
            position: sticky;
            top: 70px;
            height: calc(100vh - 152px);
            overflow: auto;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
          }

          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
  }

  .scroll-top,
  .scroll-bottom {
    position: fixed;
    display: none;
    height: 40px;
    background-color: #b7e2c1;
    left: 50%;
    transform: translateX(-50%);
    width: 20vw;
    z-index: 1000;

    &.active {
      display: block;
    }

    svg {
      display: block;
      margin: 5px auto;
      height: 30px;
      width: 30px;
      fill: $white;
    }
  }

  .scroll-top {
    top: 0;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }

  .scroll-bottom {
    bottom: 0;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.entites__block-list ::v-deep .base-card {
  position: sticky;
  top: 90px;
}

.body-main__wrapper {
  & > div.drop-area:first-child {
    @media (min-width: 1200px) {
      margin-top: 0 !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .mobile-header {
    padding-top: 15px;
  }
}
</style>
